import ScrollToTopOnMount from "components/General/ScrollToTopOnMount";
import Header from "components/General/Header";
import { Container } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

const Submitted = (props) => {  
  const isSmScreen = useMediaQuery({ query: "(max-width: 767px)" });

  return (
    <>
      <ScrollToTopOnMount />
      <Header isSmScreen={isSmScreen} />
      <main>
        <section id="impressum-content">
          <Container>
            <div className="impressum">
              <div class="form-container">Thanks for reaching out! <br/> Your form has been submitted.</div>
            </div>
          </Container>
        </section>
      </main>
    </>
  );
};

export default Submitted;
