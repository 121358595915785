import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";

import heroBg from "assets/img/main/hero/main-hero-bg.png";
import heroLight from "assets/img/main/hero/main-hero-light.png";
import heroForeground from "assets/img/main/hero/main-hero-foreground.png";
import heroMantis from "assets/img/main/hero/main-hero-mantis.png";
import heroShroom from "assets/img/main/hero/main-hero-shroom.png";
import heroScroll from "assets/img/lore/lore-scroll-icon.svg";
import hyperPlayBadge from "assets/img/general/playWithHyperPlay.png";

const Hero = ({ isMdScreen }) => {
  const [scroll, setScroll] = useState(false);

  function handleScroll(event) {
    if (window.scrollY > 0) {
      setScroll(true);
      setTimeout(
        () => document.querySelector("body").classList.remove("hidden"),
        750
      );
    }
  }

  useEffect(() => {
    document.querySelector("body").classList.add("hidden");
    window.addEventListener("scroll", handleScroll);

    return () => {
      // cancel the request before component unmounts
      window.removeEventListener("scoll", handleScroll);
    };
  }, []);

  return (
    <section
      id="start"
      className={`main-hero-section ${scroll ? "active" : ""}`}>
      <Container>
        <Row>
          <div className="text-wrap">
            <h1>Champz</h1>
            <p>A browser based RPG utilizing the $CHAMPZ token.</p>
          </div>
          <div className="cta-wrap">
            <div>
              <a
                href="https://play.champz.world"
                target="_blank"
                className="btn-main">
                Play Now
              </a>
              <a
                href="https://www.youtube.com/watch?v=FhJJ_lw_DEQ"
                target="_blank"
                rel="noreferrer"
                className="btn-main btn-secondary">
                Watch Intro
              </a>
            </div>
            <a href="" target="_blank" rel="noreferrer">
              <img src={hyperPlayBadge} alt="Download on Hyperplay" />
            </a>
          </div>
          <div className="scroll-wrap">
            <img src={heroScroll} alt="Scroll" />
          </div>
          <div className="bg-wrap">
            <img src={heroBg} alt="Bg" />
          </div>
          <div className="light-wrap">
            <img src={heroLight} alt="Light" />
          </div>
          <div className="foreground-wrap">
            <img src={heroForeground} alt="Foreground" />
          </div>
          <div className="mantis-wrap">
            <img src={heroMantis} alt="Mantis" />
          </div>
          <div className="shroom-wrap">
            <img src={heroShroom} alt="Shroom" />
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default Hero;
