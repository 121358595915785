import React from "react";
import { Container, Row } from "react-bootstrap";
import { EffectFade, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import charactersCoverOne from "assets/img/main/characters/main-characters-1.png";
import charactersCoverTwo from "assets/img/main/characters/main-characters-2.png";
import charactersCoverThree from "assets/img/main/characters/main-characters-3.png";
import charactersCoverFour from "assets/img/main/characters/main-characters-4.png";
import charactersCoverFive from "assets/img/main/characters/main-characters-5.png";
import charactersCoverSix from "assets/img/main/characters/main-characters-6.png";

const charactersSwiperData = [
  {
    id: 0,
    imgUrl: charactersCoverOne,
    title: "The Warrior",
    text: "Undeterred and stubborn, Porcini Mushrooms are one of a kind around Arborethia. Other mushrooms in the Mycociety mock them for their lack of any skill beyond fighting. But Porcini Mushrooms know their role perfectly well: they are true warriors of Arborethia, grown to serve and protect – and their chunky appearance allows them to take more blows than any other class",
    videoUrl: "https://www.youtube.com/watch?v=t2qja-doPls",
  },
  {
    id: 1,
    imgUrl: charactersCoverTwo,
    title: "The Hunter",
    text: "Despite their clumsy and cute appearance, Parasols are naturally gifted and deadly snipers that can stealthily disappear into their environment. They possess all the skills in tracking and trapping of born hunters. Like all hunters they tend to brag, but without a doubt, their attack power is unrivaled.",
    videoUrl: "https://www.youtube.com/watch?v=yr-eMqFTuK4",
  },
  {
    id: 2,
    imgUrl: charactersCoverThree,
    title: "The Priest",
    text: "Without question, Morels are the smartest mushrooms around Arborethia. The secret of their wisdom lies in their perforated brains. The natural ventilation cools their brain cells and enables them to run their data processing center at maximum power at all times. But with great power comes great respongebility. And so they have decided to use their given talents and their exhaustive understanding of the inner workings of vitality for the greater good of Arborethia and became extraordinary healers.",
    videoUrl: "https://www.youtube.com/watch?v=72dV0wD9o50",
  },
  {
    id: 3,
    imgUrl: charactersCoverFour,
    title: "The Alchemist",
    text: "Strong in will and wit, Alchemists strive towards a simple goal: to create $SPORES from scratch. But even the most talented of Alchemists have only managed to fabricate rubbish gold. Too bad – gold is a useless resource around Arborethia, as their surroundings offer it in abundance. At least, their penchant for perfectionism makes them exceptional “foragers”. Consequently, they always manage to collect some extra $SPORES that would have been overlooked (or simply ignored) by others.",
    videoUrl: "https://www.youtube.com/watch?v=nD3WbpLVBEY",
  },
  {
    id: 4,
    imgUrl: charactersCoverFive,
    title: "The Lumberjack",
    text: "Chop, eat, sleep, repeat… The life of a Lumberjack seems repetitive for outsiders, but these woodland mushrooms find fulfillment in it. By trimming scrub and chopping branchwood, they generate space for spores to grow and for other mushrooms to live. Undeniably, working with lumber all night and day rubs off on them and today, their smile is just as wooden as their limbs. Yet, the lignified body of a Lumberjack acts like an armor in battles and makes this species extremely resistant to physical attacks.",
    videoUrl: "https://www.youtube.com/watch?v=G8Vt-ayYB5I",
  },
  {
    id: 5,
    imgUrl: charactersCoverSix,
    title: "The Jester",
    text: "At first glance, Jesters are classic class clowns. However, one should not underestimate these carefree little quipsters. Their ability to always find new, creative ways to prank friends and foes is simply inimitable. Of course, it’s a fine line between madness and genius, and Jesters walk this line every single day. But their creativity enables them to adapt quickly to unforeseen circumstances and helps them to gather experience very fast.",
    videoUrl: "https://www.youtube.com/watch?v=kybb7I1-v3Y",
  },
];

const News = ({ isMdScreen }) => {
  return (
    <section id="main-characters" className="main-characters-section">
      <Container>
        <Row>
          <div className="swiper-wrap" data-aos="fade-up" data-aos-delay="300">
            <Swiper
              className="art-collection-swiper"
              slidesPerView={1}
              spaceBetween={0}
              navigation={true}
              modules={[EffectFade, Navigation]}
              effect={"fade"}
            >
              {charactersSwiperData &&
                charactersSwiperData.map(
                  ({ id, title, text, imgUrl, videoUrl }) => (
                    <SwiperSlide key={id}>
                      <div className="img-wrap">
                        <img src={imgUrl} alt="Cover" />
                      </div>
                      <div className="text-wrap">
                        <h2>Characters</h2>
                        <h4>{title}</h4>
                        <p>{text}</p>
                        <a
                          href={videoUrl}
                          target="_blank"
                          rel="noreferrer"
                          className="btn-main"
                        >
                          Watch Intro
                        </a>
                      </div>
                    </SwiperSlide>
                  )
                )}
            </Swiper>
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default News;
