import React from "react";
import { Link } from "react-router-dom";
import { Container, Row } from "react-bootstrap";

import xIcon from "assets/img/general/x-icon.svg";
import Teammember from "./teammember";

const marketingTeamData = [
  {
    id: 0,
    imgUrl: "https://champz.world/images/matsta.png",
    name: "Matsta",
    role: "CEO and Head of Development",
    xlink: "https://x.com/makeshiftNFT",
  },
  {
    id: 1,
    imgUrl: "https://champz.world/images/rawcat.jpg",
    name: "Rawcat",
    role: "Head of Game Design",
    xlink: "https://x.com/PMx_NFT",
  },
  // {
  //   id: 2,
  //   imgUrl: "https://champz.world/images/jandro.jpg",
  //   name: "Jandro",
  //   role: "Head of Marketing",
  //   xlink: "https://x.com/jandro124",
  // },
];
const coreTeamData = [
  {
    id: 0,
    imgUrl: "https://champz.world/images/enoch.jpg",
    name: "Enoch",
    role: "Outreach",
    xlink: "https://x.com/AutonomousEnoch",
  },
  {
    id: 1,
    imgUrl: "https://champz.world/images/andrew.jpg",
    name: "Andrew",
    role: "Outreach",
    xlink: "https://x.com/mrjamesports",
  },
  {
    id: 2,
    imgUrl: "https://champz.world/images/polarpanda.jpg",
    name: "Polar Panda",
    role: "Community Relations",
    xlink: "https://x.com/polarpandalife",
  },
  {
    id: 3,
    imgUrl: "https://champz.world/images/morig.PNG",
    name: "Morig",
    role: "Content Creator",
    xlink: "https://x.com/GaleKessa",
  },
  {
    id: 4,
    imgUrl: "https://champz.world/images/jael.jpg",
    name: "Jael",
    role: "Artist",
    xlink: "",
  },
  {
    id: 5,
    imgUrl: "https://champz.world/images/verrk.png",
    name: "Verrk",
    role: "Artist",
    xlink: "",
  },
  {
    id: 6,
    imgUrl: "https://champz.world/images/lyndon.png",
    name: "Lyndon",
    role: "Artist",
    xlink: "",
  },
  {
    id: 7,
    imgUrl: "https://champz.world/images/oracle.png",
    name: "Oracle",
    role: "Frontend Developer",
    xlink: "",
  },
  {
    id: 8,
    imgUrl: "https://champz.world/images/risi4.jpg",
    name: "Risi4",
    role: "Game Design",
    xlink: "",
  },
  {
    id: 9,
    imgUrl: "https://champz.world/images/sakura.jpg",
    name: "Sakura",
    role: "UI/UX Design",
    xlink: "https://x.com/SakuraWeb3",
  },
];

const extendedTeamData = [
  {
    id: 0,
    imgUrl: "https://champz.world/images/incognito.png",
    name: "Athena",
    role: "Fullstack Developer",
    xlink: "",
  },
  {
    id: 1,
    imgUrl: "https://champz.world/images/incognito.png",
    name: "Szabo",
    role: "Frontend Developer",
    xlink: "",
  },
  {
    id: 2,
    imgUrl: "https://champz.world/images/incognito.png",
    name: "Claudio",
    role: "Frontend Developer",
    xlink: "",
  },
  {
    id: 3,
    imgUrl: "https://champz.world/images/incognito.png",
    name: "Andi",
    role: "Backend Developer",
    xlink: "",
  },
  {
    id: 4,
    imgUrl: "https://champz.world/images/incognito.png",
    name: "Benedict",
    role: "Artist",
    xlink: "",
  },
  {
    id: 5,
    imgUrl: "https://champz.world/images/incognito.png",
    name: "Christoph",
    role: "Game Design",
    xlink: "",
  },
  {
    id: 6,
    imgUrl: "https://champz.world/images/incognito.png",
    name: "Natalie",
    role: "Game Design",
    xlink: "",
  },
  {
    id: 7,
    imgUrl: "https://champz.world/images/incognito.png",
    name: "Minnie",
    role: "Game Design",
    xlink: "",
  },
  {
    id: 8,
    imgUrl: "https://champz.world/images/incognito.png",
    name: "CryptoCucko0",
    role: "Game Design",
    xlink: "",
  },
];

// {
//   id: 4,
//   imgUrl: "https://champz.world/images/incognito.png",
//   name: "Benedict",
//   role: "Artist",
//   xlink: "",
// },

const Team = ({ isSmScreen }) => {
  return (
    <section id="team" className="main-team-section">
      <Container>
        <Row>
          <div className="headline-wrap" data-aos="fade-up" data-aos-delay="300">
            <h2>Our Team</h2>
          </div>
          <div className="sub-team marketing-team">
            {/* <div className="headline-wrap" data-aos="fade-up" data-aos-delay="300">
              <h3>Management Team</h3>
            </div> */}
            <div className="teams-wrap" data-aos="fade-up" data-aos-delay="600">
              {marketingTeamData &&
                marketingTeamData.map(({ id, imgUrl, name, role, xlink }) => (
                  <Teammember key={id} imgUrl={imgUrl} name={name} role={role} xlink={xlink} />
                ))}
            </div>
          </div>
          <div className="sub-team core-team">
            <div className="headline-wrap" data-aos="fade-up" data-aos-delay="300">
              <h3>Core Team</h3>
            </div>
            <div className="teams-wrap" data-aos="fade-up" data-aos-delay="600">
              {coreTeamData?.map(({ id, imgUrl, name, role, xlink }) => (
                <Teammember key={id} imgUrl={imgUrl} name={name} role={role} xlink={xlink} />
              ))}
            </div>
          </div>
          <div className="sub-team core-team">
            <div className="headline-wrap" data-aos="fade-up" data-aos-delay="300">
              <h3>Extended Team</h3>
            </div>
            <div className="teams-wrap" data-aos="fade-up" data-aos-delay="600">
              {extendedTeamData?.map(({ id, imgUrl, name, role, xlink }) => (
                <Teammember key={id} imgUrl={imgUrl} name={name} role={role} xlink={xlink} />
              ))}
            </div>
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default Team;
