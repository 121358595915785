import React from 'react';
import {Container, Row} from "react-bootstrap";

import heroScroll from 'assets/img/lore/lore-scroll-icon.svg';

const Hero = () => {
    return (
        <section id="lore-hero" className="lore-hero-section">
            <Container>
                <Row>
                    <div className="text-wrap" data-aos="fade-up" data-aos-delay="1000">
                        <h1>Champz Lore</h1>
                        <div className="scroll-wrap">
                            <img src={heroScroll} alt="Scroll"/>
                        </div>
                    </div>
                </Row>
            </Container>
        </section>
    )
}

export default Hero;
