import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import xIcon from "assets/img/general/x-icon.svg";

const Teammember = (props) => {
  return (
    <>
      <div className="team-member">
        <div className="team-member-image-container">
          <img className="team-member-image" src={props.imgUrl} />
        </div>
        <div className="team-member-description">
          <div className="team-member-info">
            <div className="team-member-name">{props.name}</div>
            <div className="team-member-position">{props.role}</div>
          </div>
          {props.xlink ? (
            <div className="team-member-socials">
              <Link to={{ pathname: props.xlink }} target="_blank" className="team-member-xlink">
                <img src={xIcon} alt="X Icon" />
              </Link>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default Teammember;
