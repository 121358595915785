import ScrollToTopOnMount from "components/General/ScrollToTopOnMount";
import Header from "components/General/Header";
import { Container } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { useForm } from "react-cool-form";
import { useState } from "react";
import axios from "axios";
import Botpoison from "@botpoison/browser";

const Field = ({ label, id, error, ...rest }) => (
  <div>
    <label htmlFor={id}>{label}</label>
    <input id={id} {...rest} />
    {error && <div>{error}</div>}
  </div>
);

const Textarea = ({ label, id, ...rest }) => (
  <div>
    <label htmlFor={id}>{label}</label>
    <textarea id={id} {...rest} />
  </div>
);

const Impressum = (props) => {
  const [isSubmitted, setSubmitted] = useState(false);
  const isMdScreen = useMediaQuery({ query: "(max-width: 992px)" });
  const isSmScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const { form, use } = useForm({
    // (Strongly advise) Provide the default values
    defaultValues: { name: "", email: "", password: "" },
    // The event only triggered when the form is valid
    onSubmit: (values) => {
      handleFormSubmit(values);
      setSubmitted(true);
    },
  });

  const handleFormSubmit = async (values) => {
    
    const botpoison = new Botpoison({
      publicKey: "pk_1e0b08b6-af15-49c2-b8f3-df0eb450d149"
    });
   

    try {
      const apiUrl = "https://submit-form.com/c6X5EwLHB"; // Replace with your API endpoint
      const { solution } = await botpoison.challenge();
      const response = await axios.post(apiUrl, {
        name: values.name,
        email: values.email,
        message: values.message,
        _botpoison: solution,
      });

      // Return the API response data
      return response.data;
    } catch (error) {
      // Handle API request errors
      console.log(error);
      // throw error;
    }
  };

  const errors = use("errors", { errorWithTouched: true }); // Default is "false"

  return (
    <>
      <ScrollToTopOnMount />
      <Header isSmScreen={isSmScreen} />
      <main>
        <section id="impressum-content">
          <Container>
            <div className="impressum">
              <div class="form-container">
                <h2> Impressum </h2>
                <br />
                RiddleDrops GmbH
                <br />
                Krenngasse 12, 8010 Graz
                <br />
                <a href="mailto:info@riddledrops.io">info@riddledrops.io</a>
                <br />
                Company Register: FN 567940 g
                <br />
                VAT: ATU77601418
                <br />
                <br />
                {isSubmitted ? (
                  <>
                    {"Form has been submitted."} <br />
                    {"Thanks for reaching out."}
                  </>
                ) : (
                  <form ref={form} noValidate>
                    <Field
                      label="Name"
                      id="name"
                      name="name"
                      // Support built-in validation
                      required
                      error={errors.username}
                    />
                    <Field
                      label="Email"
                      id="email"
                      name="email"
                      type="email"
                      required
                      error={errors.email}
                    />
                    <Textarea
                      label="Message"
                      id="message"
                      name="message"
                      required
                      style={{ verticalAlign: "middle" }}
                    />
                    <input class="btn-main" type="submit" />
                  </form>
                )}
              </div>
            </div>
          </Container>
        </section>
      </main>
    </>
  );
};

export default Impressum;
