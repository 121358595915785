import React from 'react';
import {useMediaQuery} from 'react-responsive';

import ScrollToTopOnMount from "components/General/ScrollToTopOnMount";
import Header from "components/General/Header";
import Footer from "components/General/Footer";

import Hero from "./Hero";
import Content from "./Content";

const Lore = () => {
    const isMdScreen = useMediaQuery({query: '(max-width: 992px)'});
    const isSmScreen = useMediaQuery({query: '(max-width: 767px)'});

    return (
        <>
            <ScrollToTopOnMount/>
            <Header isSmScreen={isSmScreen}/>
            <main>
                <Hero isMdScreen={isMdScreen}/>
                <Content isMdScreen={isMdScreen}/>
            </main>
            <Footer/>
        </>
    )
}

export default Lore;
