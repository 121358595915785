import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const Content = () => {
  return (
    <section id="lore-content" className="lore-content-section">
      <Container>
        <Row>
          <div className="text-wrap">
            <p>
              Every generation has its heroes. And every generation has its
              foes. As the beat of a butterfly’s wing can cause a hurricane, so
              too can a small spore evolve to the mightiest of all mushrooms and
              change the fate of the world.
            </p>
            <br />
            <p>
              The journey of every generation begins in spring. As the days grow
              longer and sunshine embraces every corner of their world, our
              brave young fungi begin to stir among the soil and leaves.
            </p>
            <br />
            <p>
              But the sun’s warmth is not the only reason to sprout. Something
              irresistible calls to the young fungi throughout the cold winter.
              A certain presence, powerful and overwhelming. A presence imbuing
              them with life.
            </p>
            <br />
            <p>
              When the day comes where the heat of the sun and moisture in the
              ground harmonise, the young fungi stretch out their limbs and open
              their eyes for the very first time. Sweeping above land they find
              the origin of the eternal presence:{" "}
              <span>Arborethia, the Tree of Life.</span>
            </p>
            <br />
            <p>
              Immutable, it is a sight to behold. Great and intertwining roots
              reaching deeply to absorb the energy of the earth’s core. A trunk
              so mighty and endless it would take ages to walk round. And a
              crown, so infinitely high above, it touches the sky.
            </p>
            <br />
            <p>
              The inimitable power of Arborethia arouses desires, both virtuous
              and destructive. Over the ages, scores of enemies have tried to
              conquer the tree and extract its sacred energy. Among those
              enemies lives none more fearsome than <span>The Swarm.</span>
            </p>
            <br />
            <p>
              The Swarm is chaos in its most natural form. A writhing mass of
              countless beetles, bugs and locusts, The Swarm is driven by
              insatiable greed and delights in destruction. It wanders around,
              leaving behind a trail of devastation in its wake.
            </p>
            <br />
            <p>
              Every year, when a new generation of mushrooms rises they make an
              oath. To protect, defend and fight for Arborethia against all
              enemies at all costs, or else lose themselves to chaos. They
              become the <span>Champignons of Arborethia</span>
            </p>
            <Col>
              <div className="cta-wrap">
                <Link
                  to={{ pathname: "http://play.champz.world" }}
                  target="_blank"
                  className="nav-link"
                >
                  <button className="btn-main">Play Now</button>
                </Link>
              </div>
            </Col>
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default Content;
