import { useEffect, useState } from "react";

const Stats_eth = () => {
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const apiURL = "https://stats-api.champz.world/eth/total";
    fetch(apiURL, {
      signal: signal,
    })
      .then((response) => response.json())
      .then((data) => {
        setTotal(data.eth.toLocaleString());
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });

    return () => {
      // cancel the request before component unmounts
      controller.abort();
    };
  }, []);

  return <>{loading ? "..." : total}</>;
};

export default Stats_eth;
