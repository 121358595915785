import React from "react";
import { useMediaQuery } from "react-responsive";

import ScrollToTopOnMount from "components/General/ScrollToTopOnMount";
import Header from "components/General/Header";
import Footer from "components/General/Footer";

import Hero from "./Hero";
import P2E from "./P2E";
import Items from "./Items";
import Statistics from "./Statistics";
import News from "./News";
import Characters from "./Characters";
import Tokenomics from "./Tokenomics";
import Impressum from "./Impressum";
import Team from "./Team";
import Partners from "./Partners";

const Main = () => {
  const isMdScreen = useMediaQuery({ query: "(max-width: 992px)" });
  const isSmScreen = useMediaQuery({ query: "(max-width: 767px)" });

  return (
    <>
      <ScrollToTopOnMount />
      <Header isSmScreen={isSmScreen} />
      <main>
        <Hero isMdScreen={isMdScreen} />

        <Characters isMdScreen={isMdScreen} />
        <Items isMdScreen={isMdScreen} />
        <News isMdScreen={isMdScreen} />
        <P2E isMdScreen={isMdScreen} />
        <Statistics isSmScreen={isSmScreen} />
        <Team isSmScreen={isSmScreen} />
        <Tokenomics isMdScreen={isMdScreen} />
        <Partners isSmScreen={isSmScreen} />
      </main>
      <Footer />
    </>
  );
};

export default Main;
