import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import logo from "assets/img/general/logo.png";
import youtubeIcon from "assets/img/general/youtube-icon.svg";
import telegramIcon from "assets/img/general/telegram-icon.svg";
import xIcon from "assets/img/general/x-icon.svg";
import tiktokIcon from "assets/img/general/tiktok-icon.svg";
import discordIcon from "assets/img/general/discord-icon.svg";

const Footer = () => {
  return (
    <footer>
      <Container>
        <Row>
          <div className="top-wrap">
            <div className="logo-wrap">
              <img src={logo} alt="Logo" />
            </div>
            <div className="links-wrap">
              <ul>
                <li>
                  <Link to="/main#start" className="nav-link">
                    Main Page
                  </Link>
                </li>
                <li>
                  <a href="/">Play to Earn</a>
                </li>
              </ul>
              <ul>
                <li>
                  <Link to="/main#tokenomics" className="nav-link">
                    Tokenomics
                  </Link>
                </li>
                <li>
                  <Link to="/lore" className="nav-link">
                    Lore
                  </Link>
                </li>
              </ul>
              <ul>
                <li>
                  <Link
                    to={{ pathname: "http://docs.champz.world" }}
                    target="_blank"
                    className="nav-link"
                  >
                    Docs
                  </Link>
                </li>
              </ul>
              <ul>
                <li>
                  <Link
                    to={{
                      pathname:
                        "https://app.toadswap.org/?input=ether&output=0x571e21a545842c6ce596663cda5caa8196ac1c7a&ref=T9Mxzh",
                    }}
                    target="_blank"
                    className="nav-link"
                  >
                    Buy $CHAMPZ on Toadswap
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname:
                        "https://opensea.io/collection/champz-official",
                    }}
                    target="_blank"
                    className="nav-link"
                  >
                    Buy Champz NFTs on OpenSea
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname:
                        "https://www.dextools.io/app/en/ether/pair-explorer/0x571E21a545842C6CE596663cdA5CaA8196AC1c7A",
                    }}
                    target="_blank"
                    className="nav-link"
                  >
                    Dextools
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="bottom-wrap">
            <div className="year-wrap">
              <p>© 2023</p>
            </div>
            <div className="copyright-wrap">
              <p>RiddleDrops GmbH - All Rights Reserved.</p>

              <div class name="impressum-wrap">
                <Link to="/impressum" className="nav-link">
                  <p>Impressum</p>
                </Link>
              </div>
            </div>
            <div className="links-wrap">
              <p>Follow us:</p>
              <ul className="socials-wrap">
                <li>
                  <Link
                    to={{ pathname: "https://www.youtube.com/@Champz_ERC" }}
                    target="_blank"
                  >
                    <img src={youtubeIcon} alt="YouTube Icon" />
                  </Link>
                </li>
                <li>
                  <Link
                    to={{ pathname: "https://t.me/champzerc" }}
                    target="_blank"
                  >
                    <img src={telegramIcon} alt="Telegram Icon" />
                  </Link>
                </li>
                <li>
                  <Link
                    to={{ pathname: "https://twitter.com/ChampzErc" }}
                    target="_blank"
                  >
                    <img src={xIcon} alt="X Icon" />
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to={{ pathname: "https://www.tiktok.com/@ChampzERC" }}
                    target="_blank"
                  >
                    <img src={tiktokIcon} alt="TikTok Icon" />
                  </Link>
                </li> */}
                <li>
                  <Link
                    to={{ pathname: "https://discord.gg/champzerc" }}
                    target="_blank"
                  >
                    <img src={discordIcon} alt="Discord Icon" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
