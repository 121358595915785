import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import Main from "./components/Main";
import Lore from "./components/Lore";
import NotFound from "./components/NotFound";
import Impressum from "components/Main/Impressum";
import Submitted from "components/Main/Submitted";

import AOS from "aos";
import "aos/dist/aos.css";
import { aosSettings } from "aosSettings";

import "./App.scss";
import Terms from "components/Main/Terms";

const App = () => {
  const appHeightHandle = () => {
    document
      .querySelector("body")
      .style.setProperty("--app-height", window.innerHeight + "px");
  };

  const isMdScreen = useMediaQuery({ query: "(max-width: 992px)" });

  let isDesktopView = !isMdScreen;

  appHeightHandle();

  window.addEventListener("resize", () => {
    appHeightHandle();
  });

  if (isDesktopView) {
    AOS.init(aosSettings);
  }

  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/" component={Main} />
          <Route exact path="/lore" component={Lore} />
          <Route exact path="/main" component={Main} />
          <Route exact path="/impressum" component={Impressum} />
          <Route exact path="/terms" component={Terms} />
          <Route exact path="/submitted" component={Submitted} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </>
  );
};

export default App;
