import React, { useState } from "react";
import { Container, Row, Modal } from "react-bootstrap";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import newInitialPatch from "assets/img/main/news/initial_patch.png";
import newsManaPatch from "assets/img/main/news/mana_patch.png";
import newsItemForgingPatch from "assets/img/main/news/forging_patch.png";
import newsLSS from "assets/img/main/news/lss_patch.png";
import newsQuests from "assets/img/main/news/quest_patch.png";
import newsGuilds from "assets/img/main/news/guild_patch.png";
import newsPvP from "assets/img/main/news/pvp.png";
import newsRevShare from "assets/img/main/news/revenue_share_patch.png";
import freetoPlay from "assets/img/main/news/free_to_play_patch.png";

import readFullArrow from "assets/img/general/read-full-arrow.svg";


const newsSwiperData = [
  {
    id: 0,
    imgUrl: freetoPlay,
    title: "Free to play",
    preview: "Champz is now officially free to play! Join Arborethia's forces and help defending our sanctum",
    text: "Champz is now officially free to play! Join Arborethia's forces and help defending our sanctum",
    version: "v1.6.5",
    date: "2024-06-11",
  },
  {
    id: 1,
    imgUrl: newsRevShare,
    title: "Revenue Share",
    preview: "We started with our Revenue Share model! Every month, 20% of all revenues generated will be given back to our $CHAMPZ holder",
    text: "We started with our Revenue Share model! Every month, 20% of all revenues generated will be given back to our $CHAMPZ holder",
    version: "v1.6.4b",
    date: "2024-06-04",
  },
  {
    id: 2,
    imgUrl: newsPvP,
    title: "Teamfights & PvP",
    preview:
      "Prepare for chaos as the battlegrounds ignite with the introduction of teamfights and PvP combat! Form alliances, clash with foes, and dominate the arena in our latest patch. Are you ready to test your skills in the ultimate showdown?",
    text: "Prepare for chaos as the battlegrounds ignite with the introduction of teamfights and PvP combat! Form alliances, clash with foes, and dominate the arena in our latest patch. Are you ready to test your skills in the ultimate showdown?",
    version: "v1.6",
    date: "2023-03-24",
  },
  {
    id: 3,
    imgUrl: newsGuilds,
    title: "NFTs & Guilds",
    preview:
      "The moment we've all been waiting for is finally here! Characters become tradeable assets, friends become Guild members, and Guild members become sparring partners!",
    text: "The moment we've all been waiting for is finally here! Characters become tradeable assets, friends become Guild members, and Guild members become sparring partners!",
    version: "v1.5",
    date: "2023-12-15",
  },
  {
    id: 4,
    imgUrl: newsQuests,
    title: "Quests & Truffles",
    preview: "Embark on epic adventures and complete quests to unearth the elusive TRUFFLES, the most coveted delicacy in the realm!",
    text: "Embark on epic adventures and complete quests to unearth the elusive TRUFFLES, the most coveted delicacy in the realm!",
    version: "v1.4",
    date: "2023-10-15",
  },
  {
    id: 5,
    imgUrl: newsLSS,
    title: "Last Champ Standing",
    preview:
      "Test your survival skills as you face relentless enemy waves. How long can you endure in the arena? It's a fight to be the Last Champ Standing!",
    text: "Test your survival skills as you face relentless enemy waves. How long can you endure in the arena? It's a fight to be the Last Champ Standing!",
    version: "v1.3",
    date: "2023-09-15",
  },
  {
    id: 6,
    imgUrl: newsItemForgingPatch,
    title: "Item forging and Consumables",
    preview:
      " Forge a path to greatness with 130 unique items, each brimming with their own rare abilities, and dive into the art of offensive and defensive combinations across 8 distinct paths. Plus, explore a whole new dimension of strategy with the introduction of Consumables - discover their secrets, and wield the power of Essences to craft the ultimate arsenal!",
    text: " Forge a path to greatness with 130 unique items, each brimming with their own rare abilities, and dive into the art of offensive and defensive combinations across 8 distinct paths. Plus, explore a whole new dimension of strategy with the introduction of Consumables - discover their secrets, and wield the power of Essences to craft the ultimate arsenal!",
    version: "v1.2",
    date: "2023-07-30",
  },
  {
    id: 7,
    imgUrl: newsManaPatch,
    title: "Mana, Spells and Effects",
    preview:
      "Unleash your inner sorcerer with a host of new abilities that will turn the tides of battle, harness the power of mana to cast epic magic spells, and strategically apply status effects to conquer foes like never before.",
    text: "Unleash your inner sorcerer with a host of new abilities that will turn the tides of battle, harness the power of mana to cast epic magic spells, and strategically apply status effects to conquer foes like never before.",
    version: "v1.1",
    date: "2023-07-05",
  },
  {
    id: 8,
    imgUrl: newInitialPatch,
    title: "Champz v1.0 started",
    preview:
      "Initial build of Champz is now live! Create your own character and engage in thrilling battles with a variety of attacks. Explore, trade in the marketplace, level up and even unlock the power of rebirth for your high-level mushrooms.",
    text: "Initial build of Champz is now live! Create your own character and engage in thrilling battles with a variety of attacks. Explore, trade in the marketplace, level up and even unlock the power of rebirth for your high-level mushrooms.",
    version: "v1.0",
    date: "2023-06-08",
  },
  // {
  //     id: 3,
  //     imgUrl: newsCoverTwo,
  //     title: 'Test Update 2.0 is Live Now!',
  //     preview: 'Undeterred and stubborn, Porcini Mushrooms are one of a kind around Arborethia. Other mushrooms in the Mycociety mock them for their lack of any skill beyond fighting. But Porcini Mushrooms know their role perfectly well: they are true warriors of Arborethia, grown to serve and protect – and their chunky appearance allows them to take more blows than any other class',
  //     text: 'Undeterred and stubborn, Porcini Mushrooms are one of a kind around Arborethia. Other mushrooms in the Mycociety mock them for their lack of any skill beyond fighting. But Porcini Mushrooms know their role perfectly well: they are true warriors of Arborethia, grown to serve and protect – and their chunky appearance allows them to take more blows than any other class',
  // },
  // {
  //     id: 4,
  //     imgUrl: newsCoverThree,
  //     title: 'Test 2 Update 2.0 is Live Now!',
  //     preview: 'Undeterred and stubborn, Porcini Mushrooms are one of a kind around Arborethia. Other mushrooms in the Mycociety mock them for their lack of any skill beyond fighting. But Porcini Mushrooms know their role perfectly well: they are true warriors of Arborethia, grown to serve and protect – and their chunky appearance allows them to take more blows than any other class',
  //     text: 'Undeterred and stubborn, Porcini Mushrooms are one of a kind around Arborethia. Other mushrooms in the Mycociety mock them for their lack of any skill beyond fighting. But Porcini Mushrooms know their role perfectly well: they are true warriors of Arborethia, grown to serve and protect – and their chunky appearance allows them to take more blows than any other class',
  // },
];

const News = ({ isMdScreen }) => {
  const [showModal, setShowModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = (id) => {
    setShowModal(true);
    setActiveIndex(id);
  };

  return (
    <section id="main-news" className="main-news-section">
      <Container>
        <Row>
          <div className="headline-wrap" data-aos="fade-up" data-aos-delay="300">
            <h2>Latest News & Updates</h2>
          </div>
          <div className="swiper-wrap" data-aos="fade-up" data-aos-delay="300">
            <Swiper
              className="art-collection-swiper"
              slidesPerView={3}
              spaceBetween={32}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                575: {
                  slidesPerView: 1.5,
                  spaceBetween: 20,
                  centeredSlides: true,
                },
                767: {
                  slidesPerView: 2.2,
                  spaceBetween: 32,
                  centeredSlides: true,
                },
                1199: {
                  slidesPerView: 3,
                  spaceBetween: 32,
                  centeredSlides: false,
                },
              }}
            >
              {newsSwiperData &&
                newsSwiperData.map(({ id, title, preview, imgUrl }) => (
                  <SwiperSlide key={id}>
                    <div className="img-wrap">
                      <img src={imgUrl} alt="Cover" />
                    </div>
                    <div className="text-wrap">
                      <button onClick={() => handleShowModal(id)}>
                        <h5>{title}</h5>
                      </button>
                      <p>{preview}</p>
                      <button onClick={() => handleShowModal(id)}>
                        Read Full{" "}
                        <span>
                          <img src={readFullArrow} alt="Arrow" />
                        </span>
                      </button>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </Row>
      </Container>
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Body>
          <div className="img-wrap">
            <img src={newsSwiperData[activeIndex]?.imgUrl} alt="Cover" />
          </div>
          <div className="text-wrap">
            <h5>{newsSwiperData[activeIndex]?.title}</h5>
            <p>{newsSwiperData[activeIndex]?.text}</p>
            <div className="version-date">
              <p>{newsSwiperData[activeIndex]?.date}</p>
              <p>{newsSwiperData[activeIndex]?.version}</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default News;
