import { useEffect, useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { Navbar } from "react-bootstrap";

import logo from "assets/img/general/logo.png";
import youtubeIcon from "assets/img/general/youtube-icon.svg";
import telegramIcon from "assets/img/general/telegram-icon.svg";
import xIcon from "assets/img/general/x-icon.svg";
import tiktokIcon from "assets/img/general/tiktok-icon.svg";
import discordIcon from "assets/img/general/discord-icon.svg";

const Header = ({ isSmScreen }) => {
  const [scroll, setScroll] = useState(false);

  function handleScroll(event) {
    setScroll(window.scrollY > 20);
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      // cancel the request before component unmounts
      window.removeEventListener("scoll", handleScroll);
    };
  }, []);

  const handleTogglerClick = () => {
    document.querySelector("body").classList.toggle("navbar-opened");
  };

  const handleNavbarClose = () => {
    document.querySelector("header .navbar .navbar-toggler").click();
    document.querySelector("body").classList.remove("navbar-opened");
  };

  return (
    <header className={`${scroll ? "scroll" : ""}`}>
      <Navbar expand="none">
        <Navbar.Toggle onClick={handleTogglerClick}>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
        </Navbar.Toggle>
        <div className="navbar-toggler-text"></div>
        <div
          className="navbar-toggler-overlay"
          onClick={handleNavbarClose}
        ></div>
        <Navbar.Collapse id="navbar-nav-dropdown">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link
                to="/main#start"
                className="nav-link"
                onClick={handleNavbarClose}
              >
                Main Page
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/main#tokenomics"
                className="nav-link"
                onClick={handleNavbarClose}
              >
                Tokenomics
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/main#statistics"
                className="nav-link"
                onClick={handleNavbarClose}
              >
                Game Statistics
              </Link>
            </li>

            <li className="nav-item">
              <Link to="/lore" className="nav-link" onClick={handleNavbarClose}>
                Lore
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={{ pathname: "http://docs.champz.world" }}
                target="_blank"
                className="nav-link"
              >
                Docs
              </Link>
            </li>
          </ul>
          {isSmScreen && (
            <ul className="navbar-socials">
              <li>
                <Link
                  to={{ pathname: "https://www.youtube.com/@Champz_ERC" }}
                  target="_blank"
                >
                  <img src={youtubeIcon} alt="YouTube Icon" />
                </Link>
              </li>
              <li>
                <Link
                  to={{ pathname: "https://t.me/champzerc" }}
                  target="_blank"
                >
                  <img src={telegramIcon} alt="Telegram Icon" />
                </Link>
              </li>
              <li>
                <Link
                  to={{ pathname: "https://twitter.com/ChampzErc" }}
                  target="_blank"
                >
                  <img src={xIcon} alt="X Icon" />
                </Link>
              </li>
              <li>
                <Link
                  to={{ pathname: "https://discord.gg/champzerc" }}
                  target="_blank"
                >
                  <img src={discordIcon} alt="Discord Icon" />
                </Link>
              </li>
            </ul>
          )}
          <div className="navbar-copyright">
            <p>© 2023</p>
            <div>
              <p>RiddleDrops GmbH - All Rights Reserved.</p>

              <Link
                to="/impressum"
                className="nav-link"
                onClick={handleNavbarClose}
              >
                Impressum
              </Link>
            </div>
          </div>
        </Navbar.Collapse>
        <Link className="navbar-brand" to="/">
          <img src={logo} alt="Logo" />
        </Link>
        {!isSmScreen && (
          <ul className="navbar-socials">
            <li>
              <Link
                to={{ pathname: "https://www.youtube.com/@Champz_ERC" }}
                target="_blank"
              >
                <img src={youtubeIcon} alt="YouTube Icon" />
              </Link>
            </li>
            <li>
              <Link to={{ pathname: "https://t.me/champzerc" }} target="_blank">
                <img src={telegramIcon} alt="Telegram Icon" />
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "https://twitter.com/ChampzErc" }}
                target="_blank"
              >
                <img src={xIcon} alt="X Icon" />
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "https://discord.gg/champzerc" }}
                target="_blank"
              >
                <img src={discordIcon} alt="Discord Icon" />
              </Link>
            </li>
          </ul>
        )}
      </Navbar>
    </header>
  );
};

export default Header;
