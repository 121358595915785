import React from "react";
import { Container, Row } from "react-bootstrap";

import itemsExplore from "assets/img/main/items/main-items-explore-img.png";
import itemsCollect from "assets/img/main/items/main-items-collect-img.png";
import itemsFight from "assets/img/main/items/main-items-fight-img.png";

const Items = ({ isMdScreen }) => {
  return (
    <section id="main-items" className="main-items-section">
      <Container>
        <Row>
          <div className="items-wrap">
            <div className="item">
              <div
                className="text-wrap"
                data-aos="fade-up"
                data-aos-delay="600"
              >
                <h2>Explore</h2>
                <p>
                  Explore the mythical world of Arborethia and shape your own
                  destiny.
                </p>
              </div>
              <div className="img-wrap" data-aos="fade-up" data-aos-delay="300">
                <img src={itemsExplore} alt="Explore" />
              </div>
            </div>
            <div className="item">
              <div className="img-wrap" data-aos="fade-up" data-aos-delay="600">
                <img src={itemsCollect} alt="Collect" />
              </div>
              <div
                className="text-wrap"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <h2>Collect</h2>
                <p>
                  Collect and trade countless items, master the art of forging,
                  create mighty gear.
                </p>
              </div>
            </div>
            <div className="item">
              <div
                className="text-wrap"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <h2>Fight</h2>
                <p>
                  Defend the tree of life, battle relentless monsters and gather
                  valuable loot.
                </p>
              </div>
              <div className="img-wrap" data-aos="fade-up" data-aos-delay="600">
                <img src={itemsFight} alt="Fight" />
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default Items;
